.mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formContainer {
    width: min(80vw, 800px);
}

.formContainerLarge {
    width: min(80vw, 1200px);
}

@media (max-width: 500px) {
    .formContainer {
        width: 100vw;
    }
    .formContainerLarge {
        width: 100vw;
    }
}