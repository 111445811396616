.navContainer {
    width: 100%;
    display: flex;
    background-color: rgb(160,160,160);
    box-shadow: 0px 1px 2px 0px rgba(60,64,67,.30),0px 2px 6px 2px rgba(60,64,67,.15)
}

.navButton {
    background-color: rgb(160,160,160);
    text-decoration: none;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.navButton:hover {
    background-color: rgb(140,140,140);
}

.navButtonLogout {
    border: none;
    cursor: pointer;
}

.navButtonLogout:hover {
    background-color: rgb(235, 129, 129);
}