.notifierContainer {
    overflow: hidden;
    /* position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%); */
    border-radius: 5px;
    background-color: var(--color-tea-green);
}

.notifierHeader {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-light-grey-green);
    overflow: hidden;
}

.notifierHeaderTitle {
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.notifierHeaderCloseBtn {
    background-color: var(--color-light-grey-green);
    border: unset;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.notifierHeaderCloseBtn:hover {
    background-color: var(--color-pale-olive);
}

.notifierBody {
    padding: 10px;
}

.notifierContainerError {
    background-color: var(--color-beauty-bush);
}

.notifierContainerError .notifierHeader {
    background-color: var(--color-pinky-rose);
}

.notifierContainerError .notifierHeaderCloseBtn {
    background-color: var(--color-pinky-rose);
}

.notifierContainerError .notifierHeaderCloseBtn:hover {
    background-color: var(--color-dusty-pink);
}