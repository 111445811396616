:root {
  --color-gray-santas: rgb(156, 153, 177);
  --color-gray-athens: rgb(248, 248, 249);
  --color-gray-dusty: rgb(151, 151, 151);
  --color-mountain-meadow: rgb(23, 191, 136);
  --color-blue-prussian: rgb(0, 36, 90);
  --color-blue-oxford: rgb(56, 67, 84);

  --color-tea-green: rgb(209, 240, 190);
  --color-light-grey-green: rgb(181, 222, 155);
  --color-pale-olive: rgb(168, 207, 144);

  --color-beauty-bush: rgb(240, 190, 190);
  --color-pinky-rose: rgb(238, 163, 163);
  --color-dusty-pink: rgb(210, 147, 147);

  --font-signika: 'Signika';
  --font-halisr: 'HalisR';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
