.companyLogo {
    height: 70px;
}

.transferLogo {
    height: 40px;
}


.header {
    width: clamp(200px, 60%, 500px);
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.title {
    font: var(--font-signika);
    font-size: 1.5em;
    color: var(--color-blue-prussian);
    text-align: center;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 40px;
}

.buttonWrapper {
    flex: 1;
}