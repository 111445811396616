.form {
    display: flex;
    flex-direction: column;
    width: '100%';
    border-radius: 10px;
    background-color: var(--color-gray-athens);
    padding: 30px;
    box-shadow: 0px 1px 2px 0px rgba(60,64,67,.30),0px 2px 6px 2px rgba(60,64,67,.15);
}

.formBodyColumn {
    flex: 1;
    min-width: min(300px, 100%);
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
}

.formBody {
    width: '100%';
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.button {
    padding: 5px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    text-decoration: none;
    background-color: white;
    color: gray;
    border: 1px solid gray;
}

.input {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    border: none;
    background-color: var(--color-gray-athens);
    border-bottom: 1px solid var(--color-blue-prussian);
    outline: none;
}

.inputError {
    border-bottom: 1px solid red;
}

.button:hover {
    color: white;
    background-color: gray;
    cursor: pointer;
}

.inputGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titleText {
    color: var(--color-blue-oxford);
    font-family: var(--font-signika);
}

.labelText {
    font-size: 1em;
    color: var(--color-blue-oxford);
    font-family: var(--font-signika);
}

.errorText {
    color: red;
    font-family: var(--font-signika);
}